import React from 'react'

// Import Components
import { LeaveBehindOffers } from '@jelly/templates'
import { SERVICES } from '../business-phone-systems'

const META = {
	canonical: `${process.env.GATSBY_SITE_HOST}/lp/compare-business-phone-systems/offers`,
	description:
		'We rate and rank the leading Business Phone Systems to help make your decision-making process easier. See up-to-date comparisons and costs for our top-rated picks.',
	robots: 'noindex, nofollow',
	title: 'Our Top 5 Business Phone Systems of 2021 - business.com',
}

const REPLACE_SERVICE_URLS = [
	{
		name: 'Nextiva',
		url:
			'https://businesscom.go2cloud.org/aff_c?offer_id=87&aff_id=1068&source=business-phone-systems&aff_sub=bestpick|best-for-crm-integration&aff_sub2=lineuprank|0&aff_sub3=deal|nextiva&aff_sub4=revenue|QT&aff_sub5=pos|ren-[marketingId]&url_id=71',
	},
	{
		name: 'RingCentral',
		url:
			'https://businesscom.go2cloud.org/aff_c?offer_id=82&aff_id=1068&source=business-phone-systems&aff_sub=bestpick|best-for-collaboration&aff_sub2=lineuprank|1&aff_sub3=deal|RingCentral&aff_sub4=revenue|QT&aff_sub5=pos|ren-[marketingId]&url_id=1',
	},
	{
		name: 'Ooma',
		url:
			'https://businesscom.go2cloud.org/aff_c?offer_id=84&aff_id=1068&source=business-phone-systems&aff_sub=bestpick|best-for-remote-teams&aff_sub2=lineuprank|2&aff_sub3=deal|Ooma&aff_sub4=revenue|QT&aff_sub5=pos|ren-[marketingId]&url_id=1',
	},
	{
		name: '8x8',
		url:
			'https://businesscom.go2cloud.org/aff_c?offer_id=83&aff_id=1068&source=business-phone-systems&aff_sub=bestpick|best-for-small-businesses&aff_sub2=lineuprank|0&aff_sub3=deal|8x8&aff_sub4=revenue|QT&aff_sub5=pos|ren-[marketingId]&url_id=1',
	},
	{
		name: 'Dialpad',
		url:
			'https://businesscom.go2cloud.org/aff_c?offer_id=97&aff_id=1068&source=business-phone-systems&aff_sub2=lineuprank|5&aff_sub3=deal|dialpad&aff_sub4=revenue|QT&aff_sub5=pos|ren-[marketingId]&url_id=1',
	},
]

function LeaveBehindOfferPage() {
	const NEW_SERVICE_OBJ = SERVICES.map((service) => {
		// temp. fix replace the service urls with different affiliate ids
		return {
			...service,
			url: REPLACE_SERVICE_URLS.filter((serv) => serv.name === service.name)[0].url,
		}
	})

	return <LeaveBehindOffers meta={META} services={NEW_SERVICE_OBJ} title="Compare our Best Business Phone Systems" />
}
export default LeaveBehindOfferPage
